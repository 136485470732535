export const questions = [
    {
      question: "Какие требования есть для отбора и поступления в alem?",
      answer:
        "Только возрастное ограничение, твой возраст должен быть от 16 до 40 лет.",
    },
    {
      question: "Что такое “Буткемп” и для чего он нужен?",
      answer:
        "“Буткемп” - это 4-недельный отбор, в течение которого студенты интенсивно обучаются языку программирования Go и работе с командной строкой (bash). По итогам буткемпа ты точно будешь знать, хочешь ли ты программировать дальше и насколько тебе подходит методология обучения.",
    },
    {
      question: "Как проходит “Буткемп”?",
      answer:
        "Наша школа открыта 24/7 и работает без учителей и лекций. Студенты могут приходить в любое удобное для них время и проходить обучение. На протяжении четырех недель студенты будут выполнять мини-проекты, защищать их и проверять других.",
    },
    {
      question: "Могу ли я совмещать “Буткемп”/учебу в alem и работу/учебу?",
      answer:
        "Да, можешь. Тебе нужно понимать, что учеба будет занимать около 40 часов в неделю. Поэтому, мы рекомендуем посвятить “буткемпу” один полный месяц.",
    },
    {
      question: "Как можно посетить школу? Есть ли экскурсии?",
      answer:
        "Да, можно. Вы можете записаться на экскурсию на сайте во вкладке “Экскурсия”.",
    },
    {
      question: "Сколько времени я должен выделять обучению в alem?",
      answer:
        "Каждый студент уникален и имеет право учиться в своем темпе. Чем больше времени ты будешь проводить в школе, тем быстрее ты закончишь. Но мы рекомендуем минимально выделять 40 часов в неделю.",
    },
    {
      question: "Могу ли я обучаться дистанционно?",
      answer:
        "Нет, так как обучение предполагает коллаборацию с другими студентами, поэтому необходимо находиться в школе для успешного прохождения программы.",
    },
    {
      question: "Обеспечиваете ли вы проживание студентам во время “Буткемп”?",
      answer:
        "Школа не обеспечивает проживание студентов во время  “Буткемп”. Но после успешного прохождения буткемпа иногородние студенты могут подать заявку на предоставление общежития.",
    },
    {
      question:
        "Получают ли студенты какие - либо документы об окончании обучения в школе alem?",
      answer:
        "Да, студенты получают сертификат негосударственного образца об окончании школы alem.",
    },
    {
      question:
        "Достаточно ли моего диплома для регистрации или мне нужно пройти обучение?",
      answer:
        "Буткемп не требует предварительных знаний. Вы можете участвовать независимо от вашей школьной истории.",
    },
    {
      question: "Можно ли пройти “Буткемп”, если я не говорю по-английски?",
      answer:
        "Знание английского языка будет преимуществом, но не является обязательным условием для обучения в нашей школе. Задачи и само программирование на английском языке, но вы можете пользоваться переводчиком для удобства.",
    },
    {
      question: "Кем финансируется проект?",
      answer:
        "Школа спонсируется меценатом. Меценат вкладывается в проект с целью найти и раскрыть таланты в области программирования и дать возможность молодым людям быть успешными.",
    },
    {
      question: "Будет ли alem помогать с трудоустройством?",
      answer:
        "Мы способствуем взаимодействию студента с работодателем для дальнейшего сотрудничества и трудоустройства.",
    },
    {
      question: "До какого уровня надо дойти при сдаче теста?",
      answer:
        "Выбор подходящего студента осуществляется автоматизированной системой, цель которой оценить потенциал каждого студента. Учитывая, что у всех разный потенциал, у всех студентов будет разный пороговый уровень.",
    },
    {
      question: "Сколько длится обучение в алем?",
      answer:
        "Срок обучения составляет 13 месяцев. Но, при интенсивном обучении вы можете закончить раньше срока.",
    },
    {
      question: "Смогут ли иностранцы учиться в школе?",
      answer: "Да, конечно. Но мы не предоставляем визовую поддержку.",
    },
    {
      question: "Могу ли я учиться на собственном ноутбуке вне школы?",
      answer:
        "Нет. Но студенты могут встречаться и обсуждать проекты (peer-to-peer) за пределами школы.",
    },
    {
      question: "Буткемпы и набор в школе проходят ежегодно?",
      answer: "Отбор в школу проходит несколько раз в год. Даты буткемпов будут заранее объявляться в наших социальных сетях и на сайте.",
    },
    {
      question: "Может ли школа предоставить отсрочку от призыва в армию?",
      answer: 
      "К сожалению, нет, но вы сможете присоединиться к нам или продолжить свое обучение после армии.",
    },
    {
      question: "Можно ли повторно пройти буткемп если не смог пройти в первый раз?",
      answer: 
      "К сожалению, буткемп можно проходить только один раз. А если вы прошли онлайн встречу после игр, но не приходили на буткемп напишите нам в телеграм @alem_support.",
    }
  ];

  export const questionskz = [
    {
      question: "Алемге іріктеу және қабылдау талаптары қандай?",
      answer: "Тек жас шектеуі, сіздің жасыңыз 16 бен 40 жас аралығында болуы керек.",
    },
    {
      question: "«Буткемп» дегеніміз не және ол не үшін қажет?",
      answer:
        "Буткемп 4 апталық іріктеу процесі болып табылады, оның барысында студенттер Go бағдарламалау тілінде және пәрмен жолында (bash) қарқынды түрде оқиды. Буткемп соңында сіз бағдарламалауды жалғастырғыңыз келетін-келмейтінін және оқыту процессінің сізге қаншалықты сәйкес келетінін нақты білесіз.",
    },
    {
      question: "«Буткемп» қалай өтеді?",
      answer:
        "Біздің мектеп оқытушыларсыз және дәрістерсіз тәулік бойы жұмыс істейді. Студенттер өздеріне ыңғайлы кез келген уақытта келіп, оқи алады. Төрт апта бойы студенттер шағын жобалар жасайды, оларды қорғайды және басқа студенттері тексереді.",
    },
    {
      question: "Алемдегі буткемпті/оқуды және жұмыс/оқуды бірге алып жүре аламын ба?",
      answer:
        "Иә, болады. Бірақ буткемпке кезінде оқу аптасына шамамен 40 сағатты алатынын түсінуіңіз керек. Сондықтан біз толық бір айды «буткемпке» арнауды ұсынамыз.",
    },
    {
      question: "Мектепке қалай баруға болады? Экскурсиялар бар ма?",
      answer:
        "Иә, экскурсия бар. Мектепке келу үшін «Экскурсияға жазылу» бөліміне өтіп форманы толтырыңыз.",
    },
    {
      question: "Алемде оқуға қанша уақыт бөлуім керек?",
      answer:
        "Әрбір студент ерекше және өз қарқынымен оқуға құқығы бар. Мектепте неғұрлым көп уақыт өткізсеңіз, соғұрлым тезірек аяқтайсыз. Бірақ біз аптасына кемінде 40 сағат оқуды ұсынамыз.",
    },
    {
      question: "Мен қашықтан оқи аламын ба?",
      answer:
        "Жоқ, оқу басқа студенттермен ынтымақтастықты көздейтіндіктен, бағдарламаны сәтті аяқтау үшін сіз мектепте болуыңыз керек.",
    },
    {
      question: "Студенттерді жатақханамен қамтамасыз етесіз бе?",
      answer:
        "Буткемп уақытына студенттерге жатақхана қарастырылмаған. Ал буткемптен кейін негізгі оқуға өткен соң егер студенттер жатақхана талаптарына сай келсе, мектеп жатақханамен қамтамасыз етеді.",
    },
    {
      question: "Студенттер алемнен оқуды аяқтағаны туралы сертификат алады ма?",
      answer:
        "Иә, оқушылар alem мектебін бітіргені туралы мемлекеттік емес сертификат алады.",
    },
    {
      question: "Тіркелу үшін менің дипломым жеткілікті ме, әлде оқудан өтуім керек пе?",
      answer:
        "Оқу лагері алдын ала білімді қажет етпейді. Сіз оқу тарихыңызға қарамастан қатыса аласыз.",
    },
    {
      question: "Ағылшын тілін білмесем, буткемпке қатысуға болады ма?",
      answer:
        "Ағылшын тілін білу артықшылық болады, бірақ біздің мектепте оқу үшін міндетті шарт емес. Тапсырмалар мен бағдарламалаудың өзі ағылшын тілінде, бірақ ыңғайлы болу үшін аудармашыны пайдалануға болады.",
    },
    {
      question: "Мектепті кім қаржыландырады?",
      answer:
        "Мектепке меценат демеушілік жасайды. Меценат бағдарламалау саласындағы таланттарды тауып, ашу және жастарға табысқа жетуге мүмкіндік беру мақсатында жобаға қаржы салады.",
    },
    {
      question: "Алем жұмысқа орналастыруға көмектеседі ме?",
      answer:
        "Оқу бағдарламасы бойынша студенттер соңғы «буткемп - интервью этапын өтеді. Бұл IT компаниялардағы шынайы интервьюларға дайындалуға көмектеседі. Бірақ біз жұмысқа орналастырмаймыз.",
    },
    {
      question: "Тест тапсыру кезінде қандай деңгейге жету керек?",
      answer:
        "Студентті таңдау автоматтандырылған жүйе арқылы жүзеге асырылады, оның мақсаты әрбір студенттің потенциалын бағалау болып табылады. Әркімнің потенциалы әртүрлі екенін ескерсек, барлық студенттердің шекті деңгейлері әртүрлі болады.",
    },
    {
      question: "Алемде оқу қанша уақытты алады?",
      answer:
        "Оқыту ұзақтығы 13 айға шақталған. Бірақ қарқынды оқысаңыз ертерек бітіре аласыз.",
    },
    {
      question: "Шетелдіктер мектепте оқи ала ма?",
      answer:
        "Иә, әрине. Бірақ біз визалық қолдау көрсетпейміз.",
    },
    {
      question: "Мен мектептен тыс уақытта өз ноутбугымда оқи аламын ба?",
      answer:
        "Жоқ. Бірақ студенттер мектептен тыс уақытта кездесіп, жобаларды талқылай алады.",
    },
    {
      question: "Жыл сайын оқу лагерлері мен мектепке қабылдау өткізіледі ме?",
      answer:
        "Мектепке қабылдау жылына бірнеше рет өткізіледі. Буткемпке тіркелу уақытын әлеуметтік желілерден және сайтымыздан біле аласыз.",
    },
    {
      question: "Мектеп әскери қызметке шақырудан кейінге қалдыруды қамтамасыз ете ала ма?",
      answer:
        "Өкінішке орай, жоқ, бірақ сіз армиядан кейін бізге қосыла аласыз немесе оқуыңызды жалғастыра аласыз.",
    },
    {
      question: "Буткемптен өте алмасам, оны қайта өтуге бола ма?",
      answer:
        "Өкінішке орай, буткемпті тек бір рет қана өтуге болады. Егер сіз ойындардан кейін онлайн кездесуден өтсеңіз, бірақ буткемпке қатыспаған болсаңыз, @alem_support телеграмына жазыңыз.",
    }
  ];
  